<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单编号" @keyup.enter.native="SearchInputKeydown" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="orderNum" label="订单编号" width="240px">
            <template slot-scope="scope">
              <el-link target="_blank" @click="toDetail(scope.row)">{{ scope.row.orderNum }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="payAmount" label="金额（元）" align="center"> </el-table-column>
          <!-- <el-table-column prop="orderType" label="订单类型" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.orderType == '1'">单账号升级</span>
                            <span v-if="scope.row.orderType == '2'">多账号升级</span>
                            <span v-if="scope.row.orderType == '3'">单账号续费</span>
                            <span v-if="scope.row.orderType == '4'">多账号续费</span>
                        </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="payWay" label="支付方式" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payWay == '1'">微信</span>
                            <span v-if="scope.row.payWay == '2'">支付宝</span>
                            <span v-if="scope.row.payWay == '3'">银行卡</span>
                        </template>
                    </el-table-column> -->
          <el-table-column prop="createTime" width="150" label="创建时间" align="center">
            <template slot-scope="scope">{{ scope.row.createTime | timefilters }}</template>
          </el-table-column>
          <el-table-column prop="orderState" label="订单状态" align="center">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.orderState == '0'">待支付</el-tag>
              <el-tag type="success" v-if="scope.row.orderState == '1'">已支付</el-tag>
              <el-tag type="info" v-if="scope.row.orderState == '-1'">取消支付</el-tag>
              <el-tag type="warning" v-if="scope.row.orderState == '2'">待审核</el-tag>
              <el-tag type="warning" v-if="scope.row.orderState == '3'">议价待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="paySuccessTime" width="150" label="支付时间" align="center">
            <template slot-scope="scope">{{ scope.row.paySuccessTime | timefilters }}</template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <span class="warning optionBtn" v-if="scope.row.orderState == '0' || scope.row.orderState == '2'" @click="CancelBtn(scope.row)">取消订单</span>
              <!-- <span class="success optionBtn" v-if="scope.row.payWay == 1 && scope.row.orderState == '0'" plain
                                @click="RechargeSave(scope.row)">继续支付</span> -->
              <!--<span size="mini" type="primary" v-if="scope.row.orderState == '0'" @click="EditBtn(scope.row)" plain>修改订单</span>-->
              <!-- <span class="danger optionBtn" @click="deleteOrder(scope.row)"
                                v-if="scope.row.orderState == '1' || scope.row.orderState == '-1'">删除</span> -->
              <span class="primaryDis optionBtn" v-if="scope.row.payWay == 3 && scope.row.orderState == '2'"> 平台审核中</span>
              <span class="success optionBtn" @click="seemychargeSave(scope.row)">明细</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!--    开票申请    -->
    <el-dialog title="开票申请" :visible.sync="invoiceDialog">
      <el-form :model="invoiceForm" ref="invoiceForm">
        <el-form-item label="发票类型" :label-width="formLabelWidth">
          <el-radio-group v-model="invoiceForm.invoiceType">
            <el-radio :label="0">增值税普通发票</el-radio>
            <el-radio :label="1">增值税专用发票</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-alert title="如果发票信息有误或者更改，请及时修改发票信息，以免造成不必要损失" type="error" show-icon :closable="false" style="margin-bottom: 15px"> </el-alert>
      <el-descriptions class="margin-top" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            企业名称
          </template>
          <el-select v-model="invoiceForm.invoiceId" value-key="id" placeholder="请选择" @change="selectInvoice($event)">
            <el-option v-for="item in InvoiceData" :key="item.id" :label="item.name" :value="item"> </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            税号
          </template>
          {{ invoiceDetail.invoiceNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            地址
          </template>
          {{ invoiceDetail.address }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            电话
          </template>
          {{ invoiceDetail.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            开户行
          </template>
          {{ invoiceDetail.bank }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            开户账号
          </template>
          {{ invoiceDetail.bankNo }}
        </el-descriptions-item>
      </el-descriptions>

      <div slot="footer" class="dialog-footer">
        <el-button @click="colseBtn('invoiceForm')">取 消</el-button>
        <el-button type="primary" @click="invoiceSave('invoiceForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    支付    -->
    <el-dialog title="支付" :visible.sync="PaymentDialog" :before-close="handleClose">
      <div class="pay-top">
        <ul class="">
          <li class="payItem clearfix">
            <p>收款账户：</p>
            <span>河南千越医疗科技有限公司</span>
          </li>
          <li class="payItem clearfix">
            <p>收款日期：</p>
            <span>{{ gettime }}</span>
          </li>
          <li class="payItem clearfix">
            <p>应付金额：</p>
            <span class="payNum">{{ payMoney }}元</span>
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <div class="pay-bg">
        <img :src="qRcodeUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { RenewalUpgrade } from '../../../components/HospitalDomain/RenewalUpgrade'
import { WeChatPay } from '../../../components/HospitalDomain/WeChatPay'
import { Distributor } from '../../../components/HospitalDomain/Distributor'
import { Invoice } from '../../../components/HospitalDomain/Invoice'
var timer = ''
export default {
  name: 'Order',
  components: {
    bread,
  },
  data() {
    var renewalUpgrade = new RenewalUpgrade(this.TokenClient, this.Services.Authorization)
    var distributor = new Distributor(this.TokenClient, this.Services.Authorization)
    var weChat = new WeChatPay(this.TokenClient, this.Services.Authorization)
    var invoice = new Invoice(this.TokenClient, this.Services.Authorization)
    return {
      RenewalDomain: renewalUpgrade,
      DistributorDomain: distributor,
      WeChatPayDomain: weChat,
      Invoice: invoice,
      tableData: [],
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      invoiceDialog: false,
      formLabelWidth: '120px',
      invoiceForm: {
        invoiceType: 0,
      },
      size: '',
      payMoney: 0,
      PaymentDialog: false,
      qRcodeUrl: '',
      gettime: '',
      weChatInfo: {},
      InvoiceData: [],
      invoiceDetail: {},
    }
  },
  mounted() {
    this.GetList()
    // this.getInvoiceInfo();
  },
  methods: {
    deleteOrder(item) {
      var _this = this
      _this
        .$confirm('删除该订单，是否继续您的操作?', '删除订单', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        })
        .then(() => {
          _this.RenewalDomain.myDeleteRenewalUpgrade(
            item.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '订单删除成功',
              })
              _this.GetList()
            },
            function (error) {
              console.log(error)
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    seemychargeSave(item) {
      this.$router.push({
        path: '/Finance/myOrderDetail',
        query: {
          orderId: item.id,
        },
      })
    },
    handleClose(done) {
      clearInterval(timer)
      this.PaymentDialog = false
      this.RechargeDialog = false
    },
    SearchInputKeydown(event) {
      if (event.keyCode == 13) {
        this.PageIndex = 1
        this.GetList()
      }
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
    search() {
      this.PageIndex = 1
      this.GetList()
    },
    GetList() {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.RenewalDomain.OrderList(
        item.startTime,
        item.endTime,
        _this.PageIndex,
        item.keyWord,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.StaffTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    CancelBtn(item) {
      var _this = this
      _this
        .$confirm('取消该订单，是否继续您的操作?', '取消订单', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        })
        .then(() => {
          _this.RenewalDomain.CancelOrder(
            item.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '订单取消成功',
              })
              _this.GetList()
            },
            function (error) {
              console.log(error)
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },

    toDetail(item) {
      this.$router.push({
        path: '/Finance/OrderDetail',
        query: {
          orderId: item.id,
        },
      })
    },
    RechargeSave(item) {
      var _this = this
      var parmas = {
        amount: item.payAmount,
        organizationId: item.organizationId,
        id: item.id,
        orderNum: item.orderNum,
        type: 1,
        attach: 'renewalUpgrade',
      }
      _this.DistributorDomain.Recharge(
        parmas,
        function (data) {
          _this.payMoney = parmas.amount
          _this.qRcodeUrl = _this.Services.Authorization + data.data.qRcodeUrl
          _this.PaymentDialog = true
          _this.weChatInfo = data.data
          var isSuccess = false
          timer = window.setInterval(() => {
            setTimeout(function () {
              _this.WeChatPayDomain.VerificationSuccessPay(
                _this.weChatInfo,
                function (data) {
                  isSuccess = data.data.isSuccess
                  if (isSuccess) {
                    clearInterval(timer)
                    _this.$message({
                      type: 'success',
                      message: '充值成功!',
                    })
                    _this.PaymentDialog = false
                    _this.RechargeDialog = false
                    _this.getList()
                  }
                },
                function (error) {
                  console.log(error)
                }
              )
            }, 0)
          }, 5000)
          setTimeout(() => {
            if (!isSuccess) {
              clearInterval(timer)
              if (_this.PaymentDialog) {
                _this.$message({
                  type: 'error',
                  message: '支付超时!',
                })
                _this.PaymentDialog = false
                _this.RechargeDialog = false
                _this.getList()
              }
            }
          }, 300000)
        },
        function (error) {
          console.log(error)
        }
      )
    },
    getInvoiceInfo() {
      var _this = this
      _this.InvoiceData = []
      _this.Invoice.InvoiceInfo(
        _this.organizationId,
        function (data) {
          _this.InvoiceData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    invoiceBtn(item) {
      this.invoiceDialog = true
      this.invoiceForm.money = item.payAmount
      this.invoiceForm.assetsDetailsIds = item.id
    },
    selectInvoice(even) {
      this.invoiceDetail = even
      console.log(even)
    },
    invoiceSave() {
      var _this = this
      var parmas = {
        invoiceType: _this.invoiceForm.invoiceType,
        organizationId: _this.organizationId,
        invoiceKind: 0,
        invoiceInfoId: _this.invoiceForm.invoiceId.id,
        operationUserId: _this.userId,
        money: _this.invoiceForm.money,
        assetsDetailsIds: [_this.invoiceForm.assetsDetailsIds],
      }
      _this.Invoice.SubmitInvoice(
        parmas,
        function (data) {
          _this.$message({
            type: 'success',
            message: '提交成功!',
          })
          _this.invoiceDialog = false
          _this.getList()
        },
        function (err) {
          console.log(err)
        }
      )
    },
  },
}
</script>

<style scoped>
.pay-top {
  position: relative;
}

.payItem {
  line-height: 2;
}

.payItem p {
  float: left;
}

.payItem span {
  float: right;
}

.payNum {
  color: red;
  font-size: 24px;
}

.pay-bg {
  position: relative;
  margin: 20px auto;
  width: 350px;
  height: 436px;
  background: url('../../../assets/img/wx_pay.jpg') top center no-repeat;
  background-size: cover;
}

.pay-bg img {
  position: absolute;
  width: 160px;
  top: 140px;
  left: 100px;
}
</style>
